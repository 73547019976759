import { ApolloClient, gql, InMemoryCache } from "@apollo/client";

const APOLLO_URI: string = process.env.GATSBY_APOLLO_URI ?? "https://api.maildrop.cc/graphql";

export const typeDefs = gql`
    type Query {
        ping(message: String): String
        inbox(mailbox: String!): [Message]
        message(mailbox: String!, id: String!): Message
        altinbox(mailbox: String!): String
        statistics: Statistics
        status: String
    }
    type Mutation {
        delete(mailbox: String!, id: String!): Boolean
    }
    type Message {
        id: String
        subject: String
        date: String
        headerfrom: String
        data: String
        html: String
    }
    type Statistics {
        blocked: Int
        saved: Int
    }
`;

export interface Message {
    id: string;
    subject: string;
    date: string;
    headerfrom: string;
    data: string;
    html: string | undefined;
}

export const client = new ApolloClient({
    uri: APOLLO_URI,
    cache: new InMemoryCache(),
    typeDefs
});

export const GET_INBOX = gql`
    query GetInbox($mailbox: String!) {
        ping(message: "Test")
        inbox(mailbox: $mailbox) {
            id
            subject
            date
            headerfrom
        }
        altinbox(mailbox: $mailbox)
    }
`;

export const GET_MESSAGE = gql`
    query GetMessage($mailbox: String!, $id: String!) {
        message(mailbox: $mailbox, id: $id) {
            id
            subject
            date
            headerfrom
            data
            html
        }
    }
`;

export const DELETE_MESSAGE = gql`
    mutation DeleteMessage($mailbox: String!, $id: String!) {
        delete(mailbox: $mailbox, id: $id)
    }
`;

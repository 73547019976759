import React, { ReactNode, useState } from "react";

export const MailboxContext = React.createContext({
    mailbox: "",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setMailbox: (_value: string) => {
        return;
    }
});

export const SuggestionContext = React.createContext({
    suggestion: "",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSuggestion: (_value: string) => {
        return;
    }
});

interface AuxWrapper {
    children: ReactNode;
}

export const MailboxWrapper = ({ children }: AuxWrapper) => {
    const [mailbox, setMailbox] = useState<string>("");
    return <MailboxContext.Provider value={{ mailbox, setMailbox }}>{children}</MailboxContext.Provider>;
};

export const SuggestionWrapper = ({ children }: AuxWrapper) => {
    const [suggestion, setSuggestion] = useState<string>("");
    return <SuggestionContext.Provider value={{ suggestion, setSuggestion }}>{children}</SuggestionContext.Provider>;
};

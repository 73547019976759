import { ApolloProvider } from "@apollo/client";
import * as React from "react";
import { client } from "./src/gql";
import "./src/styles/global.css";
import { MailboxWrapper, SuggestionWrapper } from "./src/components/context";

interface RootElement {
    element: React.ReactNode;
}

export const wrapRootElement = ({ element }: RootElement) => {
    return (
        <MailboxWrapper>
            <SuggestionWrapper>
                <ApolloProvider client={client}>{element}</ApolloProvider>
            </SuggestionWrapper>
        </MailboxWrapper>
    );
};
